import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'bx-home-circle',
    link: '/dashboard',
  },
  {
    id: 201,
    label: 'MENUITEMS.MASTERDATA.TEXT',
    icon: 'bx bx-data',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.MASTERDATA.LIST.MEDOLKHUSUS',
        link: '/medol-khusus',
        parentId: 201
      },
      {
        id: 8,
        label: 'MENUITEMS.MASTERDATA.LIST.MEDIANASIONAL',
        link: '/national-medol-list',
        parentId: 201
      },
      {
        id: 6,
        label: 'MENUITEMS.MASTERDATA.LIST.TRIBATANEWS',
        link: '/tribata-news-list',
        parentId: 201
      },
      {
        id: 7,
        label: 'MENUITEMS.MASTERDATA.LIST.MEDIADUKUNG',
        link: '/media-dukung-list',
        parentId: 201
      },
      {
        id: 2,
        label: 'MENUITEMS.MASTERDATA.LIST.CLIENT',
        link: '/client/user',
        parentId: 201
      },
      {
        id: 3,
        label: 'MENUITEMS.MASTERDATA.LIST.CLIENTSERVICE',
        link: '/client/service',
        parentId: 201
      },
      {
        id: 5,
        label: 'MENUITEMS.MASTERDATA.LIST.KATEGORIKONTEN',
        link: '/datatable/menu',
        parentId: 201
      },
      {
        id: 4,
        label: 'MENUITEMS.MASTERDATA.LIST.DSKATEGORIKONTEN',
        link: '/content-category-list',
        parentId: 201
      },
      {
        id: 6,
        label: 'MENUITEMS.MASTERDATA.LIST.MASTERVISUALISATION',
        link: '/master-visualisation',
        parentId: 201
      },

    ]
  },
  {
    id: 205,
    label: 'MENUITEMS.MONITORING.TEXT',
    icon: 'bx bx-tv',
    link: '/monitoring/daftar'
  },
  {
    id: 205,
    label: 'MENUITEMS.MONITORING-MEDIA-ONLINE.TEXT',
    icon: 'bx bx-tv',
    link: '/monitoring-media-nasional/daftar'
  },
  {
    id: 901,
    label: 'MENUITEMS.MONITORING_ACCOUNT.TEXT',
    icon: 'bx bxs-user-rectangle',
    link: '/monitoring-account/index'
  },
  {
    id: 903,
    label: 'MENUITEMS.USERACCOUNT.TEXT',
    icon: 'bx bxs-user-rectangle',
    link: '/user-account/index'
  },
  {
    id: 202,
    label: 'MENUITEMS.TREND.TEXT',
    icon: 'bx bx-trending-up',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.TREND.LIST.GOOGLE',
        link: '/trend/google',
        parentId: 202
      },
      {
        id: 2,
        label: 'MENUITEMS.TREND.LIST.TWITTER',
        link: '/trend/twitter',
        parentId: 202
      },
      {
        id: 3,
        label: 'MENUITEMS.TREND.LIST.TIKTOK',
        link: '/trend/tiktok',
        parentId: 202
      },
      {
        id: 4,
        label: 'MENUITEMS.TREND.LIST.YOUTUBE',
        link: '/trend/youtube',
        parentId: 202
      },
    ]
  },
  {
    id: 203,
    label: 'MENUITEMS.SENTIMENT.TEXT',
    icon: 'bx bx-smile',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.SENTIMENT.LIST.GOOGLE',
        link: '/sentiment/google',
        parentId: 203
      },
      {
        id: 2,
        label: 'MENUITEMS.SENTIMENT.LIST.TWITTER',
        link: '/sentiment/twitter',
        parentId: 203
      }
    ]
  },
  {
    id: 201,
    label: 'MENUITEMS.CONFIG.TEXT',
    icon: 'bx bx-cog',
    link: '/config/index'
  },
  {
    id: 801,
    label: 'MENUITEMS.ARTICLE.TEXT',
    icon: 'bx bxs-book',
    link: '/article/index',
    subItems: [
      {
        id: 790,
        label: 'MENUITEMS.CREATEARTICLE.TEXT',
        icon: 'bx bxs-book',
        link: '/article/add-article/index',
        parentId: 801
      },
      {
        id: 791,
        label: 'MENUITEMS.BANKARTICLE.TEXT',
        icon: 'bx bxs-book',
        link: '/article/bank-article/index',
        parentId: 801
      },
      {
        id: 792,
        label: 'MENUITEMS.CREATECAPTION.TEXT',
        icon: 'bx bxs-book',
        link: '/article/add-caption/index',
        parentId: 801
      },
      {
        id: 793,
        label: 'MENUITEMS.CREATECOMMENT.TEXT',
        icon: 'bx bxs-book',
        link: '/article/add-comment/index',
        parentId: 801
      },
      {
        id: 794,
        label: 'MENUITEMS.CREATEMEME.TEXT',
        icon: 'bx bxs-book',
        link: '/article/add-meme/index',
        parentId: 801
      },
      {
        id: 796,
        label: 'MENUITEMS.CREATECONTEXT.TEXT',
        icon: 'bx bxs-book',
        link: '/article/add-context/index',
        parentId: 801
      },
      {
        id: 797,
        label: 'MENUITEMS.CREATEVIDEO.TEXT',
        icon: 'bx bxs-book',
        link: '/article/add-video/index',
        parentId: 801
      },
      {
        id: 798,
        label: 'MENUITEMS.CREATEJOURNALIST.TEXT',
        icon: 'bx bxs-book',
        link: '/article/add-journalist/index',
        parentId: 801
      },
      {
        id: 799,
        label: 'MENUITEMS.CREATEINTEGRATION.TEXT',
        icon: 'bx bxs-book',
        link: '/article/add-integration/index',
        parentId: 801
      },
      {
        id: 799,
        label: 'MENUITEMS.CREATEAUTOPUBLISH.TEXT',
        icon: 'bx bxs-book',
        link: '/article/add-automation-publish/index',
        parentId: 801
      },
      {
        id: 801,
        label: 'MENUITEMS.CREATESPEECHTOTEXT.TEXT',
        icon: 'bx bxs-book',
        link: '/article/add-speech-to-text/index',
        parentId: 801
      },
    ]
  },
  {
    id: 803,
    label: 'MENUITEMS.REPORTETHICS.TEXT',
    icon: 'bx bx-user-x',
    link: '/ethics/index',
    subItems: [
      {
        id: 790,
        label: 'MENUITEMS.REPORTETHICSMANUAL.TEXT',
        link: '/ethics/report-manual/index',
        parentId: 803
      },
      {
        id: 791,
        label: 'MENUITEMS.REPORTETHICSSYSTEM.TEXT',
        link: '/ethics/report-system/index',
        parentId: 803
      },
    ]
  },
  {
    id: 294,
    label: 'MENUITEMS.TRAFFIC.TEXT',
    icon: 'bx bx-globe',
    link: '/web-traffic/index'
  },
  {
    id: 802,
    label: 'MENUITEMS.TVU.TEXT',
    icon: 'bx bx-play-circle',
    subItems: [
      {
        id: 790,
        label: 'MENUITEMS.TVUCHANNEL.TEXT',
        link: '/tvu/channel/index',
        parentId: 802
      },
      {
        id: 791,
        label: 'MENUITEMS.TVUTOKEN.TEXT',
        link: '/tvu/token/index',
        parentId: 802
      },
    ]
  },
  {
    id: 294,
    label: 'MENUITEMS.INTERACTIONS.TEXT',
    icon: 'bx bx-slideshow',
    link: '/interaction/index'
  },
  {
    id: 299,
    label: 'MENUITEMS.DATAPROCESS.TEXT',
    icon: 'bx bx-folder-plus',
    subItems: [
      {
        id: 790,
        label: 'MENUITEMS.PROCESSINDEX.TEXT',
        link: '/data-process/index',
        parentId: 299
      },
      {
        id: 791,
        label: 'MENUITEMS.PROCESSACCOUNT.TEXT',
        link: '/data-process/account/index',
        parentId: 299
      },
    ],
  },
];


export const MENU_CLIENT: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'bx-home-circle',
    badge: {
      variant: 'info',
      text: 'MENUITEMS.DASHBOARDS.BADGE',
    },
    subItems: [
      {
        id: 3,
        label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
        link: '/dashboard',
        parentId: 2
      }
    ]
  },
  {
    id: 201,
    label: 'MENUITEMS.MASTERDATA.TEXT',
    icon: 'bx bx-data',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.MASTERDATA.LIST.MEDOLKHUSUS',
        link: '/medol-khusus',
        parentId: 201
      }
    ]
  },
  {
    id: 200,
    label: 'MENUITEMS.MEDIAONLINE.TEXT',
    icon: 'bx bx-tv',
    subItems: [
      {
        id: 1,
        label: 'MENUITEMS.MEDIAONLINE.LIST.DEFAULT',
        link: '/mediaonline/daftar',
        parentId: 200
      },
      {
        id: 1,
        label: 'MENUITEMS.MEDIAONLINE.LIST.MEDSOS',
        link: '/mediasosial/daftar',
        parentId: 200
      },
    ]
  }
];

